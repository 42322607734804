import { errorMessage } from "./messages";


//  Validate Image size and type
export const validateImageFile = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const maxFileSize = 300 * 1024; // 300 KB

    if (!isJpgOrPng) {
        errorMessage('You can only upload JPG/PNG files!');
        return false;
    }

    if (file.size > maxFileSize) {
        errorMessage('File size exceeds the limit of 300 KB.');
        return false;
    }

    return true;
};
