import React, { useState } from 'react';
import { Form, Select, DatePicker, Button } from 'antd';
import { addDataToSubcollection, firestore } from '../../../services/firebase';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import { useEffect } from 'react';
import { useCurrentStaff } from '../../../context/staffContext';
import { academicStaffRoles } from "../../../constants/staffRols";
import { fetchInstitutionStaffDesignations } from './store';
import { formatToTitleCase } from '../../../utils/formatText';

function AddDesignationForm({ designationData }) {
    const [form] = Form.useForm();
    const { currentStaff } = useCurrentStaff();
    const [institutionList, setInstitutionList] = useState([]);
    const [designations, setDesignations] = useState([])


    useEffect(() => {

        const fetchData = async () => {
            try {
                const querySnapshot = await firestore.collection(collection.INSTITUTIONS_COLLECTION).orderBy("name", "asc").get();
                const fetchedData = querySnapshot.docs.map((doc) => ({
                    key: doc.id,
                    ...doc.data(),
                }));


                // Filter out items with "status": true
                const filteredDesignationData = designationData.filter(item => item.status);

                // Extract institution IDs from the filtered data
                const institutionIdsToRemove = new Set(filteredDesignationData.map(item => item.institution));

                // Filter the fetchedData array to exclude matching institutions
                const filteredInstitutions = fetchedData.filter(inst => !institutionIdsToRemove.has(inst.id));

                // Update the institution list
                setInstitutionList(filteredInstitutions);

                // console.log(filteredInstitutions);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [form, designationData]);

    // handleInstitutionChange
    const handleInstitutionChange = async (InstituionId) => {
        fetchInstitutionStaffDesignations({
            InstituionId,
            setData: setDesignations,
        });
    }
    // --- End handleInstitutionChange

    // Filter roles based on limits
    const availableRoles = Object.values(academicStaffRoles).filter((role) => {
        // Count occurrences of the role in fetchedData
        const roleCount = designations.filter((data) => data.role === role.name).length;

        // Include role if limit is not reached or if no limit is defined
        return role.limit === null || roleCount < role.limit;
    });


    // hamdle submit
    const handleSubmit = async () => {
        try {
            const docId = currentStaff.userId;
            const Staff = collection.STAFF_COLLECTION;

            const values = await form.validateFields();

            values.appointment_date = values.appointment_date.$d;
            values.staff_id = currentStaff.userId;
            values.status = true;

            addDataToSubcollection(Staff, docId, collection.STAFF_DESIGNATION_SUBCOLLECTION, values)

            form.resetFields();
            successMessage('Successfully Added');

        } catch (errorInfo) {
            errorMessage('Failed to add data: ' + errorInfo.message);
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            style={{ maxWidth: 600 }}
            initialValues={{}}>

            <Form.Item
                label="Institution"
                name="institution"
                rules={[
                    {
                        required: true,
                        message: 'Please select institution',
                    },
                ]}
            >
                <Select
                    onChange={handleInstitutionChange}
                // disabled={userData.role === userTypes.INSTITUTION_HEAD} defaultValue={userData.role === userTypes.INSTITUTION_HEAD ? userData.institution : undefined}
                >
                    {institutionList.map(institution => (
                        <Select.Option key={institution.key} value={institution.key}>
                            {institution.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="Designation"
                name="role"
                rules={[
                    {
                        required: true,
                        message: 'Please select role',
                    },
                ]}
            >
                <Select
                // onChange={handleRoleChange}
                >

                    {availableRoles.map((role) => (
                        <Select.Option key={role.name} value={role.name}>
                            {formatToTitleCase(role.name)} {/* Formatting the name */}
                        </Select.Option>
                    ))}

                </Select>
            </Form.Item>

            <Form.Item
                label="Appointment Date"
                name="appointment_date"
                rules={[{ required: true, message: 'Please select date' }]}
            >
                <DatePicker style={{ width: '100%' }} />
            </Form.Item>


            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default AddDesignationForm;

// function getOrdinalSuffix(number) {
//     const suffixes = ['th', 'st', 'nd', 'rd'];
//     const v = number % 100;
//     return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
// }
