import React, { useState } from 'react';
import { Button, Form, Steps } from 'antd';
import { firestore } from '../../../services/firebase';
import statesData from '../../../assets/json/states.json';
import districtData from '../../../assets/json/districts.json';
import localBodyData from '../../../assets/json/localBody.json';
import collection from '../../../constants/collection';
import { errorMessage, successMessage } from '../../../utils/messages';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { bloodList } from '../../../constants/blood';
import moment from 'moment';
import stepsConfig from "./formSteps"


function UpdateStaffInfoForm({ currentStaff, setDrawerOpen }) {
    const storage = firebase.storage();
    const { Step } = Steps;
    const [form] = Form.useForm();
    const [selectedDist, setSelectedDist] = useState(null);
    const [communicationDist, setCommunicationDist] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [ifSayyid, setIfSayyid] = useState(currentStaff.if_sayyid || false);
    const [uploading, setUploading] = useState(false);
    const [showWifeDetails, setShowWifeDetails] = useState(currentStaff.marital_status || 'Single');
    const [ifsameAsAddress, setIfsameAsAddress] = useState(currentStaff.if_same_Address || false);
    const [gender, setGender] = useState(currentStaff.gender || "Male");
    const [currentStep, setCurrentStep] = useState(0);

    // Find the Permanent state ID from the state name, form initial value
    const stateId = statesData.find((state) => state.name === currentStaff.state)?.id;
    // Initialize the Permanent state with the ID or null if not found
    const [selectedState, setSelectedState] = useState(stateId || null);

    // Find the Communication state ID from the state name, form initial value
    const CommunicationStateId = statesData.find((state) => state.name === currentStaff.communication_state)?.id;
    // Initialize the Communication state with the ID or null if not found
    const [communicationState, setCommunicationState] = useState(CommunicationStateId || null);

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleSubmit = async () => {
        try {
            setUploading(true); // Show button loading state
            await form.validateFields(); // Validate all fields
            const values = form.getFieldsValue(true); // Fetch all form data

            // Convert DOB to Date if it exists
            if (values.dob) {
                values.dob = values.dob.toDate(); // Convert Moment object to Date object
            }

            // Find and update Permanent State Name
            const stateName = statesData.find((state) => state.id === selectedState)?.name;
            values.state = stateName;

            // Find and update Communication State Name if available
            if (values.communication_state) {
                const commu_State = statesData.find((state) => state.id === values.communication_state)?.name;
                values.communication_state = commu_State;
            }

            // Remove empty fields before uploading
            const cleanedValues = removeEmptyFields(values);
            console.log("Form Submitted12: ", cleanedValues); // View all collected data

            // Upload image to Firebase Storage
            if (fileList.length > 0) {
                const imageFile = fileList[0].originFileObj;
                const storageRef = storage.ref(`${collection.STAFF_IMAGES_COLLECTION}/${currentStaff.userId}`);
                const uploadTask = storageRef.put(imageFile);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        // progress
                    },
                    (error) => {
                        // handle error
                        errorMessage('Error uploading file');
                    },
                    () => {
                        // upload complete
                        storageRef.getDownloadURL().then(async (url) => {
                            // Update the Firestore document with the image path
                            await firestore
                                .collection(collection.STAFF_COLLECTION)
                                .doc(currentStaff.userId)
                                .update({ ...cleanedValues, imagePath: url });

                            clossDrawer()
                            successMessage('Successfully Updated');
                        });
                    }
                );
            } else {
                await firestore
                    .collection(collection.STAFF_COLLECTION)
                    .doc(currentStaff.userId)
                    .update({ ...cleanedValues });

                clossDrawer()
                successMessage('Successfully Updated');
            }
        } catch (error) {
            console.error("Validation Error: ", error);
            errorMessage("Please complete all required fields.");
        } finally {
            setUploading(false); // Always stop button loading
        }
    };


    // after submit reset all
    const clossDrawer = () => {
        form.resetFields();
        setFileList([]);
        setDrawerOpen(false); // Close drawer
        setCurrentStep(0);   // Reset the current step to the first step
        setIfsameAsAddress(false); // Reset any additional state if necessary
    }

    //removeEmptyFields
    const removeEmptyFields = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (value !== null && value !== undefined && value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});
    };

    // Permenent Address Changes
    const handleStateChange = (value) => {
        setSelectedState(value);
        setSelectedDist()
        form.setFieldsValue({ district: undefined });
        form.setFieldsValue({ local_body: undefined });
    };
    const handleDistChange = (value) => {
        setSelectedDist(value);
        form.setFieldsValue({ local_body: undefined });
    };
    // Communication Address Changes
    const handleCommunicationStateChange = (value) => {
        setCommunicationState(value);
        setCommunicationDist()
        form.setFieldsValue({ communication_district: undefined });
        form.setFieldsValue({ communication_local_body: undefined });
    };
    const handleCommunicationDistChange = (value) => {
        setCommunicationDist(value);
        form.setFieldsValue({ communication_local_body: undefined });
    };

    // Handle handleSameAsAddress chekbox Changes
    const handleSameAsAddress = (e) => {
        const checked = e.target.checked;
        setIfsameAsAddress(checked);

        if (checked) {
            form.setFieldsValue({
                communication_house: "",
                communication_place: "",
                communication_state: null,
                communication_district: null,
                communication_local_body: null,
                communication_nationality: "",
                communication_pincode: "",
            });
        }
    };

    // Handle Marital Status Changes
    const handleStatusChange = (value) => {
        if (value === "Married") {
            setShowWifeDetails('Married'); // Show wife details when value is "Yes"
        } else {
            setShowWifeDetails(''); // Hide wife details for other cases
            // Clear spouse details
            form.setFieldsValue({
                spouse_name: '',
                spouse_phone: '',
            });
        }
    };

    const handleGenderChange = (value) => {
        setGender(value);
        // Clear spouse details when gender changes
        form.setFieldsValue({
            spouse_name: '',
            spouse_phone: '',
        });
    };


    const next = () => {
        form.validateFields()
            .then(() => {
                setCurrentStep(currentStep + 1); // Proceed to the next step if validation passes
            })
            .catch(() => {
                errorMessage("Please fill all required fields.");
            });
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };



    const steps = stepsConfig(
        statesData,
        districtData,
        localBodyData,
        handleStateChange,
        handleDistChange,
        selectedState,
        selectedDist,
        bloodList,
        ifSayyid,
        setIfSayyid,
        showWifeDetails,
        handleStatusChange,
        handleFileChange,
        fileList,
        setIfsameAsAddress,
        ifsameAsAddress,
        handleCommunicationStateChange,
        handleCommunicationDistChange,
        communicationState,
        communicationDist,
        handleSameAsAddress,
        handleGenderChange,
        gender,

    );

    return (
        <>
            <Steps current={currentStep}>
                {steps.map((item, index) => (
                    <Step key={index} title={item.title} />
                ))}
            </Steps>

            <Form
                form={form}
                layout="vertical"
                style={{ marginTop: 20 }}
                initialValues={{
                    name: currentStaff?.name || "",
                    place: currentStaff?.place || "",
                    nationality: currentStaff?.nationality || "indian",
                    house: currentStaff?.house || "",
                    state: selectedState || "", // State passed as prop
                    district: currentStaff?.district || "",
                    local_body: currentStaff?.local_body || "",
                    pincode: currentStaff?.pincode || "",
                    gender: currentStaff?.gender || "",
                    adhaar: currentStaff?.adhaar || "",
                    blood: currentStaff?.blood || "",
                    phone: currentStaff?.phone || "",
                    whatsApp: currentStaff?.whatsApp || "",
                    if_sayyid: currentStaff?.if_sayyid || false,
                    marital_status: currentStaff?.marital_status || "",
                    dob: currentStaff?.dob ? moment(currentStaff.dob.seconds * 1000) : null,
                    father_name: currentStaff?.father_name || "",
                    mother_name: currentStaff?.mother_name || "",
                    spouse_name: currentStaff?.spouse_name || "",
                    spouse_phone: currentStaff?.spouse_phone || "",
                    father_phone: currentStaff?.father_phone || "",
                    mother_phone: currentStaff?.mother_phone || "",
                    email: currentStaff?.email || "",
                    if_same_Address: ifsameAsAddress,
                    communication_house: currentStaff?.communication_house || "",
                    communication_local_body: currentStaff?.communication_local_body || "",
                    communication_nationality: currentStaff?.communication_nationality || "",
                    communication_pincode: currentStaff?.communication_pincode || "",
                    communication_place: currentStaff?.communication_place || "",
                    communication_district: currentStaff?.communication_district || "",
                    communication_state: communicationState
                }}
            >
                {steps[currentStep].content}

                <div style={{ marginTop: 20 }}>
                    {currentStep > 0 && (
                        <Button style={{ marginRight: 8 }} onClick={prev}>
                            Previous
                        </Button>
                    )}
                    {currentStep < steps.length - 1 && (
                        <Button type="primary" onClick={next}>
                            Next
                        </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <Button type="primary" onClick={handleSubmit} loading={uploading}>
                            Submit
                        </Button>
                    )}
                </div>
            </Form>
        </>
    );
}

export default UpdateStaffInfoForm;