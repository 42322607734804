import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppLayout from './core/layout/app_layout';
import { DrawerProvider } from './components/drawerProvider';
import { CurrentStudentProvider } from './context/studentContext';
import { UserDataProvider } from './context/userDataContext';
import AuthProvider from './context/AuthContext';
import { InstitutionProvider } from './context/InstitutionContext';
import { AllStudentsProvider } from './context/allStudentContext';
import { AllAcademicProvider } from './context/allAcademicContext';
import { AllReligiousProvider } from './context/allReligiousContext';
import { AllCourseProvider } from './context/allCoursesContext';
import { AllLanguageProvider } from './context/allLanguageContext';
import { CurrentStaffContext, CurrentStaffProvider } from './context/staffContext';
import { CurrentInstitutionContext, CurrentInstitutionProvider } from './context/currentInstitutionContext';
import { AllStaffProvider } from './context/allStaffContex';
import { AllSkillProvider } from './context/allSkillsContext';
import { AllAlumniProvider } from './context/allAlumniContext';

function App() {
  return (
    <Router>
      <AuthProvider>
        <DrawerProvider>
          <CurrentStudentProvider>
            <UserDataProvider>
              <InstitutionProvider>
                <AllStudentsProvider>
                  <AllAcademicProvider>
                    <AllReligiousProvider>
                      <AllCourseProvider>
                        <AllLanguageProvider>
                          <CurrentStaffProvider>
                            <CurrentInstitutionProvider>
                              <AllStaffProvider>
                                <AllSkillProvider>
                                  <AllAlumniProvider>
                                    <AppLayout />
                                  </AllAlumniProvider>
                                </AllSkillProvider>
                              </AllStaffProvider>
                            </CurrentInstitutionProvider>
                          </CurrentStaffProvider>
                        </AllLanguageProvider>
                      </AllCourseProvider>
                    </AllReligiousProvider>
                  </AllAcademicProvider>
                </AllStudentsProvider>
              </InstitutionProvider>
            </UserDataProvider>
          </CurrentStudentProvider>
        </DrawerProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
