import { firestore } from "../../../services/firebase";
import collection from "../../../constants/collection";
import { formatDOB } from "../../../utils/formatDOB";
import { errorMessage, successMessage } from "../../../utils/messages";
import { formatToTitleCase } from "../../../utils/formatText";


export const fetchDesignationDetails = ({ staffId, setData }) => {
  try {
    const query = firestore
      .collection(collection.STAFF_COLLECTION)
      .doc(staffId)
      .collection(collection.STAFF_DESIGNATION_SUBCOLLECTION)
      .orderBy('appointment_date', 'desc');
            

    // Subscribe to real-time updates
    const unsubscribe = query.onSnapshot((snapshot) => {
      const fetchedData = snapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));
      console.log(fetchedData);

      setData(fetchedData); // Update state with fetched data
    });

    // Return unsubscribe function
    return unsubscribe;
  } catch (error) {
    console.error('Error Details:', error);
  }
};


// format Data
export const formatData = (data) => {
  return data.map(item => {
    const formattedItem = {
      'id': item.id,
      "Instituion": item.institution,
      "Designation": formatToTitleCase(item.role),  // Format the role here
      "Appointment Date": formatDOB(item.appointment_date),
      'status': item.status
    }
    // Add "Relieve Date" only if it exists
    if (item.relieveDate) {
      formattedItem["Relieve Date"] = formatDOB(item.relieveDate);
    }
    return formattedItem;
  });
};
// ---- End Format Data

// export const deleteAcademicDetails = async ({ id }) => {
//   try {
//     await firestore.collection(collection.ACADEMIC_COLLECTION).doc(id).delete();
//     message.success('Academic Data removed');
//   } catch (error) {
//     message.error('Failed to remove Academic Details');
//     throw error; // Re-throw the error to handle it in the component
//   }
// };



export const updateDesignationDetails = async ({ staffId, RowId, RelieveDate }) => {

  // Get the current client-side time
  const currentTime = new Date();

  try {
    await firestore
      .collection(collection.STAFF_COLLECTION)
      .doc(staffId)
      .collection(collection.STAFF_DESIGNATION_SUBCOLLECTION)
      .doc(RowId)
      .update({
        relieveDate: RelieveDate,
        status: false,
        updated_at: currentTime,
      });
    successMessage('Successfully Updated');
  } catch (error) {
    errorMessage('Update Faild.')
    console.error('Error Update:', error);
  }
};


export const fetchInstitutionStaffDesignations = ({ InstituionId, setData }) => {
  try {
    const query = firestore
      .collectionGroup(collection.STAFF_DESIGNATION_SUBCOLLECTION)
      .where('institution', '==', `${InstituionId}`)
      .where('status', '==', true)

    // Subscribe to real-time updates
    const unsubscribe = query.onSnapshot((snapshot) => {
      const fetchedData = snapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));
      // console.log(fetchedData,'1');

      setData(fetchedData); // Update state with fetched data
    });

    // Return unsubscribe function
    return unsubscribe;
  } catch (error) {
    console.error('Error Designation Details:', error);
  }
};


