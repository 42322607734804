
// stepsConfig.js
import React from "react";
import { Row, Col, Form, Input, DatePicker, Select, Divider, Checkbox, Button, Upload } from "antd";
import { validateIndianPhoneNumber } from "../../../utils/validateMobile";
import { UploadOutlined } from '@ant-design/icons';
import { validateImageFile } from "../../../utils/fileUpload";
import ImgCrop from "antd-img-crop";



const steps = (
    statesData,
    districtData,
    localBodyData,
    handleStateChange,
    handleDistChange,
    selectedState,
    selectedDist,
    bloodList,
    ifSayyid,
    setIfSayyid,
    showWifeDetails,
    handleStatusChange,
    handleFileChange,
    fileList,
    setIfsameAsAddress,
    ifsameAsAddress,
    handleCommunicationStateChange,
    handleCommunicationDistChange,
    communicationState,
    communicationDist,
    handleSameAsAddress,
    handleGenderChange,
    gender,

) => [
        {
            title: "Personal Details",
            content: (
                <Row gutter={[16, 0]} align="bottom">
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: "Please enter the name" }]}
                        >
                            <Input placeholder="Name of Staff" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Date of Birth"
                            name="dob"
                            rules={[{ required: true, message: "Please select a date" }]}
                        >
                            <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Blood Group"
                            name="blood"
                            rules={[{ required: true, message: 'Please Select One' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Blood Group"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {bloodList.map((blood) => (
                                    <Select.Option key={blood.name} value={blood.name}>
                                        {blood.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Form.Item
                            label="Adhaar Number"
                            name="adhaar"
                            rules={[{ required: true, message: 'Please enter Adhaar Number' }]}
                        >
                            <Input placeholder="Adhaar" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Marital Status"
                            name="marital_status"
                            rules={[{ required: true, message: 'Please Select Status' }]}
                        >
                            <Select onChange={handleStatusChange}>
                                <Select.Option key="1" value="Married">
                                    Married
                                </Select.Option>
                                <Select.Option key="2" value="Single">
                                    Single
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[{ required: true, message: "Please select gender" }]}
                        >
                            <Select onChange={handleGenderChange}>
                                <Select.Option key="1" value="Male">
                                    Male
                                </Select.Option>
                                <Select.Option key="2" value="Female">
                                    Female
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                        <Form.Item name="if_sayyid" valuePropName="checked">
                            <Checkbox checked={ifSayyid} onChange={(e) => setIfSayyid(e.target.checked)}>
                                If Sayyid
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Divider orientation="left" plain>
                        Contact Details
                    </Divider>
                    <Col xs={24} sm={12} md={8} lg={8}>
                        <Form.Item
                            label="Phone Number"
                            name="phone"
                            rules={[{ required: true, validator: validateIndianPhoneNumber(true) }]}
                        >
                            <Input placeholder="Phone Number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Form.Item
                            label="WhatsApp Number"
                            name="whatsApp"
                            rules={[{ required: true, validator: validateIndianPhoneNumber() }]}
                        >
                            <Input placeholder="WhatsApp Number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="E-mail" disabled />
                        </Form.Item>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Form.Item label="Add a photo">
                            <ImgCrop
                                rotationSlider
                                aspect={2.5 / 3} // Adjust the aspect ratio (1 for a square crop, or set your desired ratio)
                                beforeCrop={() => true} >
                                <Upload
                                    fileList={fileList}
                                    onChange={handleFileChange}
                                    beforeUpload={(file) => {
                                        if (validateImageFile(file)) {
                                            return true; // Allow upload for cropping
                                        }
                                        return Upload.LIST_IGNORE; // Prevent invalid file upload
                                    }}
                                    maxCount={1} // Restrict to one file
                                >
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                    </Col>

                </Row>
            ),
        },
        {
            title: "Address Details",
            content: (
                <Row gutter={[16, 0]}>
                    <Divider orientation="left" plain>
                        Permanent Address
                    </Divider>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item
                            label="House/Apartment No"
                            name="house"
                            rules={[{ required: true, message: 'Please enter the place' }]}
                        >
                            <Input placeholder="Place of Staff" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item
                            label="Place"
                            name="place"
                            rules={[{ required: true, message: 'Please enter the address' }]}
                        >
                            <Input placeholder="Address of Staff" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Form.Item
                            label="State"
                            name="state"
                            rules={[{ required: true, message: 'Please Select State' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select State"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={handleStateChange}
                            >
                                {statesData
                                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name in ascending order
                                    .map((state) => (
                                        <Select.Option key={state.id} value={state.id}>
                                            {state.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Form.Item
                            label="District"
                            name="district"
                            rules={[{ required: true, message: 'Please Select District' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select District"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                disabled={!selectedState}
                                onChange={handleDistChange}
                            >
                                {selectedState && districtData
                                    .filter((district) => district.state_id === selectedState)
                                    .sort((a, b) => a.district.localeCompare(b.district)) // Sort by name in ascending order
                                    .map((dist) => (
                                        <Select.Option key={dist.id} value={dist.district}>
                                            {dist.district}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Form.Item
                            label="Panchayath/Municipality/Corparation"
                            name="local_body"
                            rules={[{ required: true, message: 'Please Select Local Body' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Local Body"
                                optionFilterProp="children"
                                disabled={!selectedDist}
                            >
                                {selectedDist && localBodyData
                                    .filter((local) => local.district === selectedDist)
                                    .sort((a, b) => a.local_body.localeCompare(b.local_body)) // Sort by name in ascending order
                                    .map((local_body) => (
                                        <Select.Option key={local_body.id} value={local_body.local_body}>
                                            {local_body.local_body}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Form.Item
                            label="Nationality"
                            name="nationality"
                            rules={[{ required: true, message: 'Please Select Nationality' }]}
                        >
                            <Select showSearch>
                                <Select.Option key="1" value="indian">
                                    Indian
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Form.Item
                            label="Pincode"
                            name="pincode"
                            rules={[
                                { required: true, message: 'Please enter Pincode' },
                                {
                                    pattern: /^[0-9]{6}$/, // Regex for exactly six numbers
                                    message: 'Pincode must be a six-digit number',
                                },
                            ]}
                        >
                            <Input placeholder="Pincode" />
                        </Form.Item>
                    </Col>
                    {/* Communication Address */}
                    <Divider orientation="left" plain>
                        Communication Address
                    </Divider>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item name="if_same_Address" valuePropName="checked">
                            <Checkbox checked={ifsameAsAddress} onChange={handleSameAsAddress}>
                                Same as Permanent Address
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    {!ifsameAsAddress && (
                        <>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item
                                    label="House/Apartment No"
                                    name="communication_house"
                                    rules={[{ required: true, message: 'Please enter the place' }]}
                                >
                                    <Input placeholder="Place of Staff" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item
                                    label="Place"
                                    name="communication_place"
                                    rules={[{ required: true, message: 'Please enter the address' }]}
                                >
                                    <Input placeholder="Address of Staff" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8}>
                                <Form.Item
                                    label="State"
                                    name="communication_state"
                                    rules={[{ required: true, message: 'Please Select State' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select State"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={handleCommunicationStateChange}
                                    >
                                        {statesData
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name in ascending order
                                            .map((state) => (
                                                <Select.Option key={state.id} value={state.id}>
                                                    {state.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8}>
                                <Form.Item
                                    label="District"
                                    name="communication_district"
                                    rules={[{ required: true, message: 'Please Select District' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select District"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        disabled={!communicationState}
                                        onChange={handleCommunicationDistChange}
                                    >
                                        {communicationState && districtData
                                            .filter((district) => district.state_id === communicationState)
                                            .sort((a, b) => a.district.localeCompare(b.district)) // Sort by name in ascending order
                                            .map((dist) => (
                                                <Select.Option key={dist.id} value={dist.district}>
                                                    {dist.district}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8}>
                                <Form.Item
                                    label="Panchayath/Municipality/Corparation"
                                    name="communication_local_body"
                                    rules={[{ required: true, message: 'Please Select Local Body' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Local Body"
                                        optionFilterProp="children"
                                        disabled={!communicationDist}
                                    >
                                        {communicationDist && localBodyData
                                            .filter((local) => local.district === communicationDist)
                                            .sort((a, b) => a.local_body.localeCompare(b.local_body)) // Sort by name in ascending order
                                            .map((local_body) => (
                                                <Select.Option key={local_body.id} value={local_body.local_body}>
                                                    {local_body.local_body}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8}>
                                <Form.Item
                                    label="Nationality"
                                    name="communication_nationality"
                                    rules={[{ required: true, message: 'Please Select Nationality' }]}
                                >
                                    <Select showSearch>
                                        <Select.Option key="1" value="indian">
                                            Indian
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8}>
                                <Form.Item
                                    label="Pincode"
                                    name="communication_pincode"
                                    rules={[
                                        { required: true, message: 'Please enter Pincode' },
                                        {
                                            pattern: /^[0-9]{6}$/, // Regex for exactly six numbers
                                            message: 'Pincode must be a six-digit number',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Pincode" />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
            ),
        },
        {
            title: "Family Details",
            content: (
                <Row gutter={[16, 0]}>
                    <Divider orientation="left" plain>
                        Family Details
                    </Divider>

                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item
                            label="Father Name"
                            name="father_name"
                            rules={[{ required: true, message: 'Please enter the name' }]}
                        >
                            <Input placeholder="Name of Father" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item
                            label="Phone Number"
                            name="father_phone"
                            rules={[{ validator: validateIndianPhoneNumber() }]}
                        >
                            <Input placeholder="Phone Number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item
                            label="Mother Name"
                            name="mother_name"
                            rules={[{ required: true, message: 'Please enter the name' }]}
                        >
                            <Input placeholder="Name of Mother" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item
                            label="Phone Number"
                            name="mother_phone"
                            rules={[{ validator: validateIndianPhoneNumber() }]}
                        >
                            <Input placeholder="Phone Number" />
                        </Form.Item>
                    </Col>

                    {showWifeDetails === 'Married' && (
                        <>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item
                                    label={gender === "Male" ? "Wife Name" : "Husband Name"}
                                    name="spouse_name"
                                    rules={[{ required: true, message: 'Please enter the name' }]}
                                >
                                    <Input placeholder={`Enter ${gender === "Male" ? "Wife" : "Husband"} Name`} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item
                                    label={gender === "Male" ? "Wife Phone" : "Husband Phone"}
                                    name="spouse_phone"
                                    rules={[{ validator: validateIndianPhoneNumber() }]}
                                >
                                    <Input placeholder={`Enter ${gender === "Male" ? "Wife" : "Husband"} Phone`} />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
            ),
        },
    ];

export default steps;
