/* StaffDetails.jsx */
import React from 'react';
import { Card, Tabs, Spin } from 'antd';
import categories from './categories'; // Ensure categories is relevant for staff or create a similar file for staff
import { useCurrentStaff } from '../../context/staffContext'; // Assume a similar context for staff exists

function StaffDetails() {
  const { currentStaff } = useCurrentStaff(); // Using a hypothetical useCurrentStaff hook
  // Check if currentStaff is null or undefined
  if (!currentStaff) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Card type='inner' title={currentStaff.name}
        headStyle={{
          backgroundColor: '#595959',
          color: 'white'
        }}
      >
        <Tabs defaultActiveKey="1" items={categories} />
      </Card>
    </>
  );
}

export default StaffDetails;