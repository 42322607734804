// do not remove any data from this file 
// do not change any data from this file
// you can add data when needed

export const statusList = [
    { 'id': 1, "name": "Active" }, 
    { 'id': 2, "name": "Completed" }, 
    { 'id': 3, "name": "Drop out" },
]

export const resons = [
    {  "name": "Higher Education" }, 
    { "name": "Stopped" }, 
]


export const typeList = [
    { 'id': 1, "name": "Regular" }, 
    { 'id': 2, "name": "Private" }, 
    { 'id': 3, "name": "Distance" },
]


//academic details
export const levelList = [ 
    { "id": 1, "name": "UP" }, 
    { "id": 2, "name": "High School" }, 
    { "id": 3, "name": "Higher Secondary" }, 
    { "id": 4, "name": "Under Graduation" }, 
    { "id": 5, "name": "Post Graduation" }, 
    { "id": 6, "name": "PhD" },
    { "id": 7, "name": "Diploma" },
    { "id": 8, "name": "B.Ed" },
    { "id": 9, "name": "M.Ed" },
    { "id": 10, "name": "LLB" },
    { "id": 11, "name": "Advaced Diploma" },
    { "id": 12, "name": "Junior Diploma" },
    { "id": 13, "name": "Senior Diploma" },
    { "id": 14, "name": "Certificate" },
]
export const boardList = [
    { "id": 1, "name": "Secondary Education Board of Kerala" }, 
    { "id": 2, "name": "CBSE" },
    { "id": 3, "name": "NIOS" },
    { "id": 4, "name": "Board of Higher Secondary Examination, Tamilnadu" },
    { "id": 5, "name": "Karnataka Secondary Education Examination Board" },
    { "id": 6, "name": "Directorate of Higher Secondary Education Kerala" },
    { "id": 7, "name": "Vocational Higher Secondary Education Kerala" },
]

export const universityList = [
    { "id": 1, "name": "Calicut University" }, 
    { "id": 2, "name": "Kerala University" }, 
    { "id": 3, "name": "IGNOU" }, 
    { "id": 4, "name": "Kannur University" },
    { "id": 5, "name": "Thunchath Ezhuthachan Malayalam University" },
    { "id": 6, "name": "Sree Narayanaguru Open University" },
    { "id": 7, "name": "Jawaharlal Nehru University" },
    { "id": 8, "name": "Delhi University" },
    { "id": 9, "name": "Jamia Millia University" },
    { "id": 10, "name": "Aligarh Muslim University" },
    { "id": 11, "name": "Azim Premji University Bangalore" },
    { "id": 12, "name": "Pondicherry University" },
    { "id": 13, "name": "Central University of Kerala, Kasargod" },
    { "id": 14, "name": "English and Foreign Language University" },
    { "id": 15, "name": "Osmania University Hyderabad" },
    { "id": 16, "name": "MANUU - Hyderabad" },
    { "id": 17, "name": "MG University Kottayam" },
    { "id": 18, "name": "IGNTU Madhya Pradesh" },
    { "id": 20, "name": "Assam University, Silchar" },
    { "id": 21, "name": "Babasaheb Bhimrao Ambedkar University, Lucknow" },
    { "id": 22, "name": "Banaras Hindu University, Varanasi" },
    { "id": 23, "name": "Central Agricultural University, Imphal" },
    { "id": 24, "name": "Central University of Andhra Pradesh, Anantapur" },
    { "id": 25, "name": "Central University of South Bihar, Gaya" },
    { "id": 26, "name": "Central University of Gujarat, Gandhinagar" },
    { "id": 27, "name": "Central University of Haryana, Mahendragarh" },
    { "id": 28, "name": "Central University of Himachal Pradesh, Dharamshala" },
    { "id": 29, "name": "Central University of Jammu, Jammu" },
    { "id": 30, "name": "Central University of Jharkhand, Ranchi" },
    { "id": 31, "name": "Central University of Karnataka, Gulbarga" },
    { "id": 32, "name": "Central University of Kashmir, Srinagar" },
    { "id": 34, "name": "Central University of Odisha, Koraput" },
    { "id": 35, "name": "Central University of Punjab, Bathinda" },
    { "id": 36, "name": "Central University of Rajasthan, Ajmer" },
    { "id": 37, "name": "Central University of Tamil Nadu, Thiruvarur" },
    { "id": 38, "name": "Dr. Harisingh Gour University, Sagar" },
    { "id": 39, "name": "Guru Ghasidas Vishwavidyalaya, Bilaspur" },
    { "id": 40, "name": "Hemvati Nandan Bahuguna Garhwal University, Srinagar" },
    { "id": 41, "name": "Indira Gandhi National Tribal University, Amarkantak" },
    { "id": 44, "name": "Mahatma Gandhi Antarrashtriya Hindi Vishwavidyalaya, Wardha" },
    { "id": 45, "name": "Manipur University, Imphal" },
    { "id": 46, "name": "Maulana Azad National Urdu University, Hyderabad" },
    { "id": 47, "name": "Mizoram University, Aizawl" },
    { "id": 48, "name": "Nagaland University, Lumami" },
    { "id": 49, "name": "North Eastern Hill University, Shillong" },
    { "id": 51, "name": "Rajiv Gandhi University, Itanagar" },
    { "id": 52, "name": "Sikkim University, Gangtok" },
    { "id": 53, "name": "Tezpur University, Tezpur" },
    { "id": 55, "name": "Tripura University, Agartala" },
    { "id": 56, "name": "University of Allahabad, Allahabad" },
    { "id": 58, "name": "University of Hyderabad, Hyderabad" },
    { "id": 59, "name": "Visva-Bharati University, Santiniketan" },
    { "id": 60, "name": "Cochin University of Science and Technology (CUSAT)" },
    { "id": 61, "name": "University of Madras" },
    { "id": 62, "name": "Bharathiyar University" },
    { "id": 63, "name": "Karnataka State Open University" },
    { "id": 64, "name": "Annamalai University" },
    { "id": 65, "name": "Rayalseema University" },
    { "id": 67, "name": "APJ Abdulkalam Technical University" },
    { "id": 68, "name": "Tamil Nadu Teachers Education University" },
    { "id": 69, "name": "Manglore University" },
    { "id": 70, "name": "Capital University Koderma, Jharkhand" },
    { "id": 71, "name": "Karnataka State Law University " },
    { "id": 72, "name": "State Board of Technical Education, Kerala" },
];


export const streamList = [
    { "id": 1, "name": "Regular"}, 
    { "id": 2, "name": "Open" }, 
    { "id": 3, "name": "Private" },
    { "id": 4, "name": "Open Regular" },
    { "id": 5, "name": "Online" },
]
export const academicStatusList = [
    { "id": 1, "name": "Ongoing" }, 
    { "id": 2, "name": "Completed" }, 
    { "id": 3, "name": "Drop out" },
]
export const termList = [
    { "id": 1, "no_of_terms": 1 }, 
    { "id": 2, "no_of_terms": 2 }, 
    { "id": 3, "no_of_terms": 3 }, 
    { "id": 4, "no_of_terms": 4 }, 
    { "id": 5, "no_of_terms": 5 }, 
    { "id": 6, "no_of_terms": 6 }, 
    { "id": 7, "no_of_terms": 7 }, 
    { "id": 8, "no_of_terms": 8 },
]
export const collegeList = [
    { "id": 1, "name": "Govt. College, Malappuram" }, 
    { "id": 2, "name": "Ma'din Arts And Sciece College" }, 
    { "id": 3, "name": "Ma'din College of Engineering and Management" }, 
    { "id": 4, "name": "Malabar College, Vengara" }, 
    { "id": 5, "name": "Priyadarshini Arts and Science college" }, 
    { "id": 6, "name": "GEMS Ramapuram" }, 
    { "id": 7, "name": "Govt. College, Kondotti" }, 
    { "id": 8, "name": "PSMO College" }, 
    { "id": 9, "name": "NSS College, Manjeri" }, 
    { "id": 10, "name": "EMEA College, Kondotti" },
    { "id": 11, "name": "HM college, Mariyad" }, 
    { "id": 12, "name": "Ma'din ITI" },   
    { "id": 13, "name": "MCT College, Malappuram" }, 
    { "id": 14, "name": "MEA Engineering college, Perinthalmanna" }, 
    { "id": 0, "name": "Other" }, 
]

export const schoolList = [
    { "id": 1, "name": "Boys Higher Secondary School, Malappuram" },
    { "id": 2, "name": "Pookkottur Government Higher Secondary School" },
    { "id": 3, "name": "Ma'din Higher Secondary School Edupark" },
    { "id": 4, "name": "Ma'din Public School Swalath Nagar" },
    { "id": 5, "name": "MSP Higher Secondary School Malappuram" },
    { "id": 6, "name": "Government Higher Secondary School Irumbuzhi" },
    { "id": 7, "name": "Government Boys Higher Secondary School Manjeri" },
    { "id": 8, "name": "Darul Uloom Higher Secondary School Panakkad" },
    { "id": 9, "name": "MMET Higher Secondary School Melmuri" },
    { "id": 10, "name": "PMSA Vocational Higher Secondary School Chapanangadi" },
    { "id": 11, "name": "Government Vocational Higher Secondary School Pullanoor" },
    { "id": 12, "name": "MIC EMHSS Athanikkal" },
    { "id": 13, "name": "Al Irshad Higher Secondary School, Trippanachi" },
    { "id": 14, "name": "Ma'din public school, Kadannamanna" },
    { "id": 0, "name": "Other" }, 
];

export const  hssStreamList = [
    { "id": 1, "name": "Science" },
    { "id": 2, "name": "Commerce" },
    { "id": 3, "name": "Humanities" },
]



//religious educational deatails
export const religiousLevelList = [ 
    { "id": 1, "name": "Madrasa 7th" }, 
    { "id": 2, "name": "Madrasa 10th" }, 
    { "id": 3, "name": "Madrasa 12th" }, 
    { "id": 4, "name": "Ma'din UP " }, 
    { "id": 5, "name": "Ma'din High School" },
    { "id": 6, "name": "Ma'din Higher Secondary" },
    { "id": 7, "name": "Ma'din Degree" },
    { "id": 8, "name": "Ma'din PG" },
    { "id": 9, "name": "Adany" },
]