import { firestore } from '../../services/firebase';
import collection from '../../constants/collection';
import userTypes from '../../constants/userTypes';

export const fetchStaffs = async ({ setData, filter = {} }) => {
  try {
    let query = firestore.collection(collection.STAFF_COLLECTION);

    // Apply filter if provided
    if (filter.institution) {
      query = query.where('institution', '==', filter.institution);
    }

    const unsubscribe = query.onSnapshot(snapshot => {
      const staffs = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      // console.log(staffs);
      setData(staffs);
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  } catch (error) {
    console.error('Error fetching staffs:', error);
  }
};

export const formatData = (data, institutionList) => {
  return data.map(item => {


    const formattedItem = {
      "name": item.name,
      "role": item.role,
      'email': item.email,
      "institution": item.institution,
    };

    return formattedItem;
  });
};

export const fetchAttendanceData = (userData, callback) => {
  try {
    let query = firestore.collection(collection.ATTENDANCE_LIST_COLLECTION);

    // Add a where condition if the user is an INSTITUTION_HEAD
    if (userData.role === userTypes.INSTITUTION_HEAD) {
      query = query.where('institution', '==', userData.institution);
    }

    const unsubscribe = query.onSnapshot(snapshot => {
      const attendanceList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      callback(attendanceList);
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  } catch (error) {
    console.error('Error fetching attendance data:', error);
  }
};

// function capitalizeWords(str) {
//   return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
// }
