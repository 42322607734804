import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Card, Descriptions, Button, Drawer, Divider, Avatar } from 'antd';
import { useCurrentStaff } from '../../../context/staffContext';
import { fetchBasicDetails, formatBasicData } from './store';
import UpdateStaffInfoForm from './UpdateStaffInfoForm';
import { UserOutlined } from '@ant-design/icons';


function StaffBasicInfo() {
  const { currentStaff } = useCurrentStaff();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [basicData, setBasicData] = useState({});

  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const unsubscribe = fetchBasicDetails({
      staffId: currentStaff.userId,
      setData: setBasicData
    });

    return () => {
      unsubscribe();
    };
  }, [currentStaff.userId]);

  const formattedData = formatBasicData(basicData); // Adjusted for staff
  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col span={4} style={{ textAlign: 'center' }}>
          {basicData.imagePath ? (
            <Image width="100%" src={basicData.imagePath} />
          ) : (
            <Avatar
              shape="square"
              style={{
                width: '100%',
                height: '50%',
                aspectRatio: '1 / 1',
                fontSize: '900%', // Adjusts the icon size of the Avatar
              }}
              icon={<UserOutlined />}
            />
          )}
          <p style={{ marginBottom: '0' }}>Staff's ID Number:</p>
          <h3 style={{ margin: '0' }}>{basicData.ID_NO}</h3>
        </Col>

        <Col span={20}>
          <Card
            type="inner"
            title="Basic Info"
            extra={<>
              <Button onClick={openDrawer}>Update</Button>
            </>}
            style={{ marginLeft: 10 }}
          >
            <Drawer title="Update Basic Info" width={650} onClose={closeDrawer} open={isDrawerOpen}>
              <UpdateStaffInfoForm currentStaff={basicData} setDrawerOpen={setDrawerOpen}
              />
            </Drawer>

            {formattedData.map((section, index) => (
              <div key={index}>
                <Divider orientation="left">{section.title}</Divider>
                <Descriptions layout="horizontal">
                  {Object.entries(section.data).map(([label, content]) => (
                    <Descriptions.Item label={label} key={label}>
                      {content}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </div>
            ))}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default StaffBasicInfo;