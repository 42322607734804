import { firestore } from "../../../services/firebase";
import collection from "../../../constants/collection";
import { errorMessage } from "../../../utils/messages";
import { formatDOB } from "../../../utils/formatDOB";

export const fetchBasicDetails = ({ staffId, setData }) => {
    try {
        const query = firestore.collection(collection.STAFF_COLLECTION).doc(staffId);

        // Get data from the specific document
        return query.onSnapshot((doc) => {
            if (doc.exists) {
                // Document exists, extract its data
                const fetchedData = {
                    key: doc.id,
                    ...doc.data(),
                };
                setData(fetchedData); // Update state with fetched data
            } else {
                // Document doesn't exist
                errorMessage('Data not Updated')
            }
        }, (error) => {
            console.error("Error getting document:", error);
        });
    } catch (error) {
        console.error('Error fetching basic details:', error);
    }
};



function calculateAge(dobTimestamp) {
    const dobDate = new Date(dobTimestamp.seconds * 1000); // Convert seconds to milliseconds
    const today = new Date();
    let age = today.getFullYear() - dobDate.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();

    // Adjust age if the birthday hasn't occurred yet this year
    if (month < dobDate.getMonth() || (month === dobDate.getMonth() && day < dobDate.getDate())) {
        age--;
    }
    return age;
}

export const formatBasicData = (item) => {

    return [
        {
            title: "Basic Information",
            data: {
                // "Name": item.name,
                "Adhaar": item.adhaar,
                "Blood Group": item.blood,
                "Date of Birth": item.dob ? formatDOB(item.dob) : null,
                "Marital Status": item.marital_status,
                "Gender": item.gender,
                "Age": item.dob ? calculateAge(item.dob) : null,
            }
        }, {
            title: "Contact",
            data: {
                "Phone Number": item.phone,
                "WhatsApp Number": item.whatsApp,
                "E-mail": item.email,
            }
        }, {
            title: "Family Details",
            data: {

                "Father Name": item.father_name,
                "Mother Name": item.mother_name,
                [`${item.gender === "Male" ? "Wife" : "Husband"} Name`]: item.spouse_name, // Dynamic key for spouse
                "Father Number": item.father_phone,
                "Mother Number": item.mother_phone,
                [`${item.gender === "Male" ? "Wife" : "Husband"} Number`]: item.spouse_phone, // Dynamic key for spouse's number
            }
        }, {
            title: "Permanent Address",
            data: {
                "House/Apartment No": item.house,
                "Place": item.place,
                "Local Bodie": item.local_body,
                "District": item.district,
                "State": item.state,
                "Nationality": item.nationality,
                "Pincode": item.pincode,
            }
        }, item.if_same_Address
            ? {
                title: "Communication Address",
                data: {
                    "Message": "Same as Permanent Address",
                },
            }
            : {
                title: "Communication Address",
                data: {
                    "House/Apartment No": item.communication_house,
                    "Place": item.communication_place,
                    "Local Body": item.communication_local_body,
                    "District": item.communication_district,
                    "State": item.communication_state,
                    "Nationality": item.communication_nationality,
                    "Pincode": item.communication_pincode,
                },
            },
    ];
};
