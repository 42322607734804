// Utility function for Indian phone number validation

export const validateIndianPhoneNumber = (isRequired = false) => {
    return (_, value) => {
        if (!value) {
            if (isRequired) {
                return Promise.reject(new Error(''));

            } else {
                return Promise.resolve(); // Allow empty if not required
            }
        }
        const pattern = /^[6-9]\d{9}$/;
        if (!pattern.test(value)) {
            return Promise.reject(new Error('Please enter a valid Phone Number!'));
        }
        return Promise.resolve();
    };
};