import StaffBasicInfo from "./basic/StaffBasicInfo";
import DesignationDetails from "./designation/Designation_Details";



const    categories =[
    {
        key: '1',
        label: 'Basic Info',
        children: <StaffBasicInfo/>,
      
    },
    {
        key: '2',
        label: 'Designation',
        children: <DesignationDetails/>,
    },
]

export default categories;