import React, { useState, useEffect } from 'react';
import { Card, Drawer, Button, Input, Statistic, Row, Col, Select, Modal } from 'antd'; // Import Select and Modal
import { useNavigate } from 'react-router-dom';
import { useCurrentStaff } from '../../context/staffContext';
import { useInstitution } from '../../context/InstitutionContext';
import AppButton from '../../components/app_button';
import AppTable from '../../components/app_table';
import '../../assets/styles.css';
import AddStaffForm from './AddStaffForm';
import { fetchStaffs, formatData, fetchAttendanceData } from './store'; // Ensure formatData is imported
import { useUserData } from '../../context/userDataContext';
import userTypes from '../../constants/userTypes';
import { addDataToFirestore } from '../../services/firebase'; // Import the function
import collection from '../../constants/collection'; // Import the collection constants

const { Search } = Input;
const { Option } = Select;

function Staffs() {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { setStaffData } = useCurrentStaff();
  const navigate = useNavigate();
  const { institutionList } = useInstitution();
  const { userData } = useUserData();

  const [totalClassesToday, setTotalClassesToday] = useState(0);
  const [nowOnCampus, setNowOnCampus] = useState(0);
  const [outOfCampus, setOutOfCampus] = useState(0);
  const [attendanceFilter, setAttendanceFilter] = useState('all'); // New state for filter

  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [selectedInstitution, setSelectedInstitution] = useState(null); // State for selected institution
  const [selectedRecord, setSelectedRecord] = useState(null); // State for the selected record

  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Institution',
      dataIndex: 'institution',
      render: (institutionId) => {
        // console.log(institutionList);
        const institution = institutionList.find(inst => inst.id === institutionId);
        return institution ? institution.name : "Ma'din Academy";
      },
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <span>
          <Button type='link' onClick={() => handleViewClick(record)}>View</Button>
        </span>
      ),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    // Add a new column for actions if the user is an admin
    // ...(userData.role === 'admin' ? [{
    //   title: 'Actions',
    //   render: (text, record) => (
    //     <Button onClick={() => handleCopyClick(record)}>Copy</Button>
    //   ),
    // }] : []),
  ];

  const handleViewClick = (record) => {
    // console.log(record);
    setStaffData(record);
    navigate(`/staff-details`);
  };

  const handleCopyClick = (record) => {
    // console.log('Action clicked for:', record);
    setSelectedRecord(record); // Store the selected record
    setIsModalVisible(true); // Show the modal when the button is clicked
  };

  const handleModalOk = async () => {
    if (selectedInstitution && selectedRecord) {
      try {
        // Create a new document in the staff collection with the updated institution ID
        const newRecord = { ...selectedRecord, institution: selectedInstitution };
        delete newRecord.id; // Remove the id from the new record
        await addDataToFirestore(collection.STAFF_COLLECTION, newRecord);
        // console.log('New staff record added:', newRecord);
      } catch (error) {
        // console.error('Error adding new staff record:', error);
      }
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      let filter = {};
      // console.log(userData.institution);
      if (userData.role === userTypes.INSTITUTION_HEAD) {
        filter = { institution: userData.institution };
      }
      const unsubscribe = fetchStaffs({ setData: setTableData, filter });

      // Cleanup subscription on unmount
      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    };
    fetchData();
  }, [userData.role, userData.institution]);

  useEffect(() => {
    const formattedData = formatData(tableData, institutionList);
    setFilteredData(formattedData);
  }, [tableData, institutionList]);

  useEffect(() => {
    const calculateAttendanceStats = async () => {
      fetchAttendanceData(userData, (attendanceList) => {
        const today = new Date().toISOString().split('T')[0];
        const completedStaffIds = new Set();
        const scheduledStaffIds = new Set();

        attendanceList.forEach(attendance => {
          if (attendance.date === today) {
            if (attendance.staffIds && attendance.staffIds.length > 0) {
              attendance.staffIds.forEach(staffId => {
                scheduledStaffIds.add(staffId);
                if (attendance.attended) {
                  completedStaffIds.add(staffId);
                }
              });
            }
          }
        });

        setTotalClassesToday(completedStaffIds.size);
        setNowOnCampus(scheduledStaffIds.size - completedStaffIds.size);
        setOutOfCampus(tableData.length - scheduledStaffIds.size);

        // Apply filter based on attendance status
        applyAttendanceFilter(completedStaffIds, scheduledStaffIds);
      });
    };

    calculateAttendanceStats();
  }, [tableData, attendanceFilter]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    applyFilters(query);
  };

  const applyFilters = (query) => {
    const dataToFilter = attendanceFilter === 'all' ? tableData : filteredData;
    const filtered = dataToFilter.filter(staff => {
      const name = staff.name ? staff.name.toLowerCase() : '';
      const email = staff.email ? staff.email.toLowerCase() : '';
      const institution = staff.institution ? staff.institution.toLowerCase() : '';

      return name.includes(query) || email.includes(query) || institution.includes(query);
    });

    setFilteredData(filtered);
  };

  const applyAttendanceFilter = (completedStaffIds, scheduledStaffIds) => {
    let filtered = tableData;

    if (attendanceFilter === 'completed') {
      filtered = tableData.filter(staff => completedStaffIds.has(staff.id));
    } else if (attendanceFilter === 'scheduled') {
      filtered = tableData.filter(staff => scheduledStaffIds.has(staff.id) && !completedStaffIds.has(staff.id));
    } else if (attendanceFilter === 'notScheduled') {
      filtered = tableData.filter(staff => !scheduledStaffIds.has(staff.id));
    }

    setFilteredData(filtered);
  };

  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Total Faculties"
              value={tableData.length}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Faculties Completed a Lecture Today"
              value={totalClassesToday}
              valueStyle={{ color: '#007bff' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Faculties not Completed a Lecture Today"
              value={nowOnCampus}
              valueStyle={{ color: '#28a745' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Faculties Not Scheduled any Lecture Today"
              value={outOfCampus}
              valueStyle={{ color: '#dc3545' }}
            />
          </Card>
        </Col>
      </Row>

      <Card
        title="Staffs"
        extra={<AppButton text='Add Staff' onClick={openDrawer} />}
      >
        <Drawer title='Add Staff' width={500} onClose={closeDrawer} open={isDrawerOpen}>
          <AddStaffForm />
        </Drawer>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <Search
            placeholder="Search staffs"
            value={searchQuery}
            onChange={handleSearch}
            style={{ width: '100%', maxWidth: '300px' }}
          />
          <Select
            value={attendanceFilter}
            onChange={setAttendanceFilter}
            style={{ width: 200 }}
          >
            <Option value="all">All</Option>
            <Option value="completed">Completed</Option>
            <Option value="scheduled">Scheduled but not Completed</Option>
            <Option value="notScheduled">Not Scheduled</Option>
          </Select>
        </div>

        <AppTable columns={columns} data={filteredData} />

        {/* <Modal
          title="Select Institution"
          visible={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
        >
          <Select
            style={{ width: '100%' }}
            placeholder="Select an institution"
            onChange={setSelectedInstitution}
            value={selectedInstitution}
          >
            {institutionList.map(inst => (
              <Option key={inst.id} value={inst.id}>
                {inst.name}
              </Option>
            ))}
          </Select>
        </Modal> */}
      </Card>
    </div>
  );
}

export default Staffs;
