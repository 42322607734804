import React, { useState, useEffect } from 'react';
import { Card, Descriptions, Button, DatePicker, Modal, message } from 'antd';
import AddDesignation from './AddDesignation';
import { fetchDesignationDetails, formatData, updateDesignationDetails } from './store';
import { useUserData } from '../../../context/userDataContext';
import userTypes from "../../../constants/userTypes";
import { useCurrentStaff } from '../../../context/staffContext';
import { useInstitution } from '../../../context/InstitutionContext';
import useFetchInstitutions from '../../../utils/getInstitutions';
import moment from 'moment';


function DesignationDetails() {
  const { currentStaff } = useCurrentStaff();
  // const { institutionList } = useInstitution();
  const [designationData, setDesignationData] = useState([]);
  const { userData } = useUserData();
  const { institutionList } = useFetchInstitutions();


  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [relieveDate, setRelieveDate] = useState(null);
  const [currentItemId, setCurrentItemId] = useState(null); // Track the current item ID for the modal

  const [joinDate, setjoinDate] = useState()


  useEffect(() => {
    if (currentStaff.userId) {
      const unsubscribe = fetchDesignationDetails({
        staffId: currentStaff.userId,
        setData: setDesignationData,
      });
      // useFetchInstitutions()

      // Ensure unsubscribe is a function
      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    }
  }, [currentStaff.userId]);

  // const handleDelete = async (documentId) => {
  //   try {
  //     await deleteAcademicDetails({ id: documentId });
  //     // Refresh the data after deletion
  //     // setAcademicData((prevData) => prevData.filter((item) => item.key !== documentId));
  //   } catch (error) {
  //     console.error('Error deleting document:', error);
  //   }
  // };

  const formattedData = formatData(designationData);

  const getInstitutionName = (institutionId) => {

    const institution = institutionList.find(inst => inst.id === institutionId);
    return institution ? institution.name : 'Madin Academy';
  };


  // Model Functions
  const handleRelieveClick = (id, date) => {
    setjoinDate(date)
    setCurrentItemId(id); // Set the ID of the item being relieved
    setIsDatePickerVisible(true); // Show the date picker
  };

  const handleDateChange = (date) => {
    setRelieveDate(date); // Store the selected relieve date
  };

  // submit Relieve Date
  const handleModelSubmit = async () => {
    if (!relieveDate) {
      message.error('Relieve date is required!');
      return; // Don't submit if date is not selected
    }
    const staffId = currentStaff.userId;
    const RelieveDate = relieveDate.$d;
    const RowId = currentItemId;
    try {

      await updateDesignationDetails({ staffId, RowId, RelieveDate })

      setIsDatePickerVisible(false); // Close the date picker modal after submission
      setCurrentItemId(null)
      setRelieveDate(null)
    } catch (error) {
      setIsDatePickerVisible(false);
      setCurrentItemId(null)
      setRelieveDate(null)
    }

  };

  // ---- End Model Functions

  return (
    <div>
      {userData.role === "admin" ? (<AddDesignation designationData={designationData} />) : null}
      {/* {userData.role !== userTypes.STUDENT ? <AddDesignation designationData={designationData} /> : null} */}

      {formattedData.map((item) => (
        <div key={item.id} style={{ marginBottom: 10 }}>
          <Card
            type='inner'
            title={getInstitutionName(item.Instituion)}

            extra={
              userData.role === "admin" && item.status === true ? ( // Check if item.status is true
                <>
                  <Button danger onClick={() => handleRelieveClick(item.id, item['Appointment Date'])}>Relieve</Button> {/* Pass item.id here */}
                </>
              ) : null // Do not render anything if status is not true
            }
            style={{ marginLeft: 10 }}
          >
            <Descriptions layout='horizontal'>
              {Object.keys(item)
                .filter(key => key !== 'id' && key !== 'Instituion' && key !== 'status') // Filter out 'id' and 'Instituion'
                .map((key) => (
                  <Descriptions.Item key={key} label={key}>{item[key]}</Descriptions.Item> // Combine id with key for unique key
                ))}

            </Descriptions>
          </Card>
        </div>
      ))
      }


      {/* Relieve Date Picker Modal */}
      <Modal
        title="Select Relieve Date"
        open={isDatePickerVisible}
        onCancel={() => setIsDatePickerVisible(false)} // Close date picker modal
        onOk={handleModelSubmit} // Submit the date
      >
        <DatePicker
          value={relieveDate}
          onChange={handleDateChange}
          disabledDate={(current) => {
            // Disable dates before the dynamically set join date
            return current && current < moment(joinDate, 'DD-MM-YYYY');
          }}
        // format="YYYY-MM-DD" // Adjust date format if needed
        />
      </Modal>
      {/*---- End Relieve Date Picker Modal */}

    </div >
  );
}

export default DesignationDetails;

