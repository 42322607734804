export const academicStaffRoles = {
    PRINCIPAL: { name: 'principal', limit: 1 },
    VICE_PRINCIPAL: { name: 'vice_principal', limit: 1 },
    DIRECTOR: { name: 'director', limit: 1 },
    ACADEMIC_ADVISOR: { name: 'academic_advisor', limit: 2 },
    ADMINISTRATIVE_OFFICER: { name: 'administrative_officer', limit: null },
    MUDARRIS: { name: 'mudarris', limit: null },
    MENTOR: { name: 'mentor', limit: null },
    ACCOUNTANT: { name: 'accountant', limit: null },
};


