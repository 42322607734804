import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useUserData } from '../context/userDataContext';
import { useCurrentStudent } from '../context/studentContext';
import { firestore } from '../services/firebase';
import collection from '../constants/collection';
import { getRoutesByRole } from './user_routes';
import userTypes from '../constants/userTypes';
import { useCurrentInstitution } from '../context/currentInstitutionContext';
import { useCurrentStaff } from '../context/staffContext';



const fetchStudentData = async (userId, setStudentData, setLoading) => {
  if (!userId) return;
  try {
    const studentDoc = await firestore.collection(collection.STUDENTS_COLLECTION).doc(userId).get();
    setStudentData(studentDoc.data());
  } catch (error) {
    console.error('Error fetching student data:', error);
  } finally {
    setLoading(false);
  }
};

const fetchStaffData = async (userId, setStaffData, setLoading) => {
  if (!userId) return;
  try {
    const studentDoc = await firestore.collection(collection.STAFF_COLLECTION).doc(userId).get();
    setStaffData(studentDoc.data());
  } catch (error) {
    console.error('Error fetching student data:', error);
  } finally {
    setLoading(false);
  }
};

function AppRoutes() {
  const { userData } = useUserData();
  const { setStudentData } = useCurrentStudent();
  const { setStaffData } = useCurrentStaff();
  const { currentInstitution } = useCurrentInstitution();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      if (!userData) {
        setLoading(false); // Exit early if userData is not available
        return;
      }

      setLoading(true); // Set loading to true before fetching data

      try {
        if (userData.role === userTypes.STUDENT) {
          await fetchStudentData(userData.userId, setStudentData, setLoading);
        } else if (userData.role === userTypes.STAFF) {
          await fetchStaffData(userData.userId, setStaffData, setLoading); // Assuming fetchStaffData exists
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Ensure loading is false after operation completes
      }
    };

    fetchData();
  }, [userData,]);


  const routesToRender = userData
    ? (userData.role === userTypes.INSTITUTION_HEAD && currentInstitution?.features)
      ? getRoutesByRole(userData.role, currentInstitution.features)
      : getRoutesByRole(userData.role)
    : [];


  if (loading || (userData.role === userTypes.INSTITUTION_HEAD && !currentInstitution)) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      {routesToRender.map((route, index) => (
        <Route key={index} path={route.path} element={<route.component />} />
      ))}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
}


export default AppRoutes;